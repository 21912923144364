<script>
import useVuelidate from '@vuelidate/core'

/* COMPOSANTS */
import AkInputNumber from "@components/input/AkInputNumber";
import AkTime from "@components/input/AkTime";
import Panel from 'primevue/panel';
import Menu from 'primevue/menu';

/* MIXINS */
import randomRef from "@mixins/randomRef";

export default {
  components: {AkInputNumber, AkTime, Panel, Menu},
  mixins: [randomRef],
  emits: ['copyRow', 'pasteRow'],
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      items: [
        {
          label: this.$t('options'),
          items: [
            {
              label: this.$t('copy'),
              icon: 'pi pi-copy',
              command: () => { this.copy() },
            },
            {
              label: this.$t('paste'),
              icon: 'pi pi-clone',
              command: () => { this.paste() },
            }
          ]
        }
      ]
    }
  },
  props: {
    row: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    disabled : {
      type: Boolean,
      required: false,
      default: false
    }
  },
  validations() {
    return {
      row: {}
    }
  },
  methods: {
    copy() {
      this.$emit('copyRow', this.index);
    },
    paste() {
      this.$emit('pasteRow', this.index);
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
  },
  computed: {
    currentValue: {
      get() {
        return this.row;
      }
    }
  }
}
</script>

<template>
  <Panel style="display: inline-block; margin-bottom: 14px" class="col-sm">
    <template #header>Circuit n°{{ currentValue.number }}&nbsp;&nbsp;-&nbsp;&nbsp;{{ currentValue.axis}}</template>
    <template #icons v-if=!disabled>
      <button type="button" class="p-panel-header-icon p-link mr-2" @click="toggle($event)">
        <span class="pi pi-clone"></span>
      </button>
      <Menu id="config_menu" ref="menu" :model="items" :popup="true"/>
    </template>
    <div class="form-row">
      <AkInputNumber
        :disabled=disabled
        :min=1
        :max=2
        placeholder=" "
        v-model="currentValue.nbConduite"
        :validator="v$.row.nbConduite"
        :label="$t('bilan_circuit.nb_conduite')"
        class-name="col-md-12"/>
    </div>
    <div class="form-row">
      <AkInputNumber
        :disabled=disabled
        placeholder=" "
        v-model="currentValue.lineaireTotal"
        :validator="v$.row.lineaireTotal"
        :label="$t('bilan_circuit.lineaire_total')"
        class-name="col-md-12"/>
    </div>
    <div class="form-row">
      <AkInputNumber
        :disabled=disabled
        placeholder=" "
        v-model="currentValue.lineaireSalage"
        :validator="v$.row.lineaireSalage"
        :label="$t('bilan_circuit.lineaire_salage')"
        class-name="col-md-12"/>
    </div>
    <div class="form-row">
      <AkInputNumber
        :disabled=disabled
        placeholder=" "
        v-model="currentValue.surfaceSalage"
        :validator="v$.row.surfaceSalage"
        :label="$t('bilan_circuit.surface_salage')"
        class-name="col-md-12"/>
    </div>
    <div class="form-row">
      <AkTime
        :disabled=disabled
        v-model="currentValue.dureeEstimeTraitementAsString"
        :validator="v$.row.dureeEstimeTraitementAsString"
        :label="$t('bilan_circuit.duree_estime_traitement')"
        class-name="col-md-12"/>
    </div>
    <div class="form-row">
      <AkTime
        :disabled=disabled
        v-model="currentValue.dureeEstimeParcoursAsString"
        :validator="v$.row.dureeEstimeParcoursAsString"
        :label="$t('bilan_circuit.duree_estime_parcours')"
        class-name="col-md-12"/>
    </div>
    <div class="form-row">
      <AkInputNumber
        :minFractionDigits=1
        :maxFractionDigits=2
        :disabled=disabled
        placeholder=" "
        v-model="currentValue.tonnageEstimeSel"
        :validator="v$.row.tonnageEstimeSel"
        :label="$t('bilan_circuit.tonnage_estime_sel')"
        class-name="col-md-12"/>
    </div>
    <div class="form-row">
      <AkInputNumber
        :disabled=disabled
        placeholder=" "
        v-model="currentValue.capaciteSaleuse"
        :validator="v$.row.capaciteSaleuse"
        :label="$t('bilan_circuit.capacite_saleuse')"
        class-name="col-md-12"/>
    </div>
  </Panel>
</template>